const projects = [
  // {
  //   id: "p1",
  //   category: "New Builds",
  //   slug: "modern-villa",
  //   title: "Modern Villa",
  //   imgURL: "portfolio/p1/boxas-p1-a.jpg",
  //   description: "A luxurious modern villa designed for elegance and comfort.",
  //   date: "2023-05-20",
  //   location: "London, UK",
  // },
  // {
  //   id: "p2",
  //   category: "Extensions and Conversions",
  //   slug: "loft-conversion",
  //   title: "Loft Conversion",
  //   imgURL: "portfolio/p2/boxas-p2-a.jpg",
  //   description: "A beautiful loft conversion with maximised space and light.",
  //   date: "2022-10-12",
  //   location: "Manchester, UK",
  // },
  // {
  //   id: "p3",
  //   category: "Computer Generated Images",
  //   slug: "3d-apartment",
  //   title: "3D Apartment Rendering",
  //   imgURL: "portfolio/p3/boxas-p3-a.jpg",
  //   description: "A stunning 3D visualisation of a high-rise apartment complex.",
  //   date: "2021-07-15",
  //   location: "Birmingham, UK",
  // },

  // New Builds (Updated from Word Docs)
  {
    id: "p004",
    category: "New Builds",
    slug: "the-elders",
    title: "The Elders",
    imgURL: "portfolio/p004/P004-01.jpg",
    description: "The Elders is a new build residential development in Barnard Castle, made up of two different house types creating four dwellings: each with five bedrooms. Each plot size was maximised by utilising the roof to create master bedrooms. Traditional construction materials were used to compliment the surrounding area that is mainly stone and slate dwellings complete with sash windows. The development was in the rear garden to an existing dwelling that had a large curtilage. Every effort was made to retain the existing trees that were good specimens where possible and worked them into the scheme. ",
    date: "",
    location: "Barnard Castle",
  },
  {
    id: "p007",
    category: "New Builds",
    slug: "toronto",
    title: "Toronto",
    imgURL: "portfolio/p007/P007-02.jpg",
    description: "The project is a six plot, new build residential development in Toronto, but only five are being constructed until the client’s children need their own house and this approval has been implemented and therefore extant. The project required a Change of Use from Agriculture to Residential. There are three house types with one house type being modified several times to the requirement of each self-build occupier. Each plot size was maximised by utilising the roof to create master bedrooms. Traditional construction materials were used to complement the surrounding area that is mainly stone and slate dwellings with sash windows, however, the natural light was maximised with a twist to the fenestration to each of the properties with large fenestration areas to the front elevations and bi-fold doors to the rear of the properties. ",
    date: "",
    location: "Toronto",
  },
	{
    id: "p015",
    category: "New Builds",
    slug: "the-garden-room",
    title: "The Garden Room",
    imgURL: "portfolio/p015/P015-01.JPG",
    description: "This project was for a replacement garden room in the curtilage of a Grade II listed building in Brandon Village. Careful design considerations were required due to the building being located within the curtilage of a listed building; therefore, the bricks were specifically sourced to complement the existing building with a slate and glazed roof complete with a stone water table. Timber doors and windows were installed painted in heritage colour to coincide with the host dwelling. ",
    date: "",
    location: "Brandon Village",
  },
  {
    id: "p035",
    category: "New Builds",
    slug: "deneburn-court",
    title: "Deneburn Court",
    imgURL: "portfolio/p035/P035-01.jpg",
    description: "This 4-plot development on Etherley Lane, Bishop Auckland was constructed using stone frontages with brick side and rear elevations, which were the signature building style of the developer. Large triple glazing to the front elevations were used to give a distinctive, coherent design style for the development, maximising natural light and views from each of the dwellings. There are two house types; each with three storey’s by utilising the roof space for master bedroom complete with large ensuites and dressing areas to each house type. The properties overlook the stream and coppice to the rear. ",
    date: "",
    location: "Bishop Auckland",
  },
  {
    id: "p038",
    category: "New Builds",
    slug: "east-roughlea-farm",
    title: "East Roughlea Farm",
    imgURL: "portfolio/p038/P038-01.jpg",
    description: "This project involved construction of a worker’s dwelling for a retiring farmer, in the County Durham area. The dwelling replaced an existing outbuilding that was used for domestic storage and used the same footprint as the outbuilding. The new build property allows the farmer to stay on the farm he and his family have ran for decades. The new dwelling provided a three-bedroom property with open plan living space and master bedroom to the ground floor. The first floor has two guest bedrooms and a family bathroom. The style and materials used for the construction complemented the existing farmhouse, which was then occupied by the retiring farmer’s family.",
    date: "",
    location: "County Durham",
  },
  {
    id: "p053",
    category: "New Builds",
    slug: "etherley-farm",
    title: "Etherley Farm",
    imgURL: "portfolio/p053/P053-02.jpg",
    description: "This project was for the construction of a six-bedroom detached dwelling, located on Etherley Lane in Bishop Auckland. This property is one of three proposed dwellings that are located within the grounds of a former farmhouse. The materials used reflect that of the existing host dwelling and the two other proposed units, using natural stone to the walls, traditional sash windows, bifold doors to the rear that overlooks a small watercourse and protected coppice and a natural slate roof finish.",
    date: "",
    location: "Bishop Auckland",
  },
	{
    id: "p067",
    category: "New Builds",
    slug: "folly-house",
    title: "Folly House",
    imgURL: "portfolio/p067/P067-02.jpg",
    description: "This project was for a double garage with storage to the first floor within the grounds of an existing dwelling. The property, located in Westerton Heights, Bishop Auckland, has been future proofed so that it can be converted to a habitable annexe should the client want to and subject to the relevant consents. The existing garage, that was no longer fit for purpose, was demolished to accommodate the new building. The style of the new building complemented the host dwelling as did the materials using render finish to the walls and black concrete plain tiles as the roof finish. ",
    date: "",
    location: "Westerton Heights, Bishop Auckland",
  },
  {
    id: "p071",
    category: "New Builds",
    slug: "poplar-containers",
    title: "Poplar Containers",
    imgURL: "portfolio/p071/P071-05.jpg",
    description: "This project was to prepare the building regulation package for the installation of four holiday cottages, each made up of two upcycled shipping containers bound together. The site is to the rear of Poplar Tree Garden Centre in Shincliffe Village. Each cottage has two sleeping areas and an open plan living space. Large glazing areas have been introduced to best take advantage of the views on offer being situated within the open countryside. Each unit is clad in timber rainscreen cladding, uses anthracite-coloured doors and windows and a single-ply membrane for the roof finish. The energy performance of the buildings was satisfied by using multifoil insulation. Two of the units are wheelchair accessible.",
    date: "",
    location: "Shincliffe Village",
  },

  // Extensions & Conversions (Empty Fields for Future Data)
	{
    id: "p000",
    category: "Extensions and Conversions",
    slug: "alder-grove",
    title: "Alder Grove",
    imgURL: "portfolio/p000/P000-01.JPG",
    description: "This substantial two-storey rear extension of 52msq to an existing house in Seaham created an open plan living/ kitchen/ dining area to the ground floor and an additional ensuite master bedroom and a larger bedroom to the first floor following some remodelling of the internal layout. All existing doors and windows were replaced to give a modern look to what was a plain building. Bi-fold doors were used to the rear elevation to bring the outdoor living space to the inside.",
    date: "",
    location: "Seaham",
  },
  {
    id: "p003",
    category: "Extensions and Conversions",
    slug: "elemore-grange-farm-extension",
    title: "Elemore Grange Farm Extension",
    imgURL: "portfolio/p003/P003-1.jpg",
    description: "This project was a large, two-storey, rear extension and a single-storey side extension, creating approximately 100m2 of additional floor area, at a property in Littletown that created separate living quarters on the ground floor for a wheelchair bound family member. As part of the works - the internal layout was fully adapted to be wheelchair accessible throughout. The existing plot size was increased to facilitate larger bedrooms to the first floor and a large open plan living/dining room to the ground floor. Large bi-fold doors, Juliet balconies and an outside balcony make the most of views that the plot has to offer. All the existing doors and windows of the existing dwelling were replaced as well as the pink render that was replaced with white render. The improvements help to improve the aesthetic appearance of the external facades. The external hardstanding has also received of attention to facilitate level access thresholds. An outdoor disused storage building was also converted on the same site to a self-contained annexe for the main house. The external appearance was altered to match the alterations to the main house to ensure a cohesive project with each building complimenting the other.",
    date: "",
    location: "Littletown",
  },
  {
    id: "p010",
    category: "Extensions and Conversions",
    slug: "primrose-lane-extension",
    title: "Primrose Lane Extension",
    imgURL: "portfolio/p010/P010-02.JPG",
    description: "A single storey rear extension was added to this existing building to create an open plan kitchen/dining/living area that also required internal reconfiguration to accommodate this. The client was very clear that they wanted a modern extension that contrasted completely the existing building, but that was affordable. It was decided that decorative rainscreen cladding using natural slate would be used and was affordable but that gave a modern look. Flat rooflights ensured that there was sufficient natural light to the new space. The existing kitchen wall and floor units were removed and replaced with one central island, the revised layout provided a playroom and snug room, which were required by the client as part of their brief.  Large full width bi-fold doors allow the internal space to be opened up fully to the outdoor area.",
    date: "",
    location: "",
  },
  {
    id: "p011",
    category: "Extensions and Conversions",
    slug: "bishop-auckland-town-council-extension",
    title: "Bishop Auckland Town Council Extension",
    imgURL: "portfolio/p011/P011-06.JPG",
    description: "The existing building, that was of a former architectural practice, was extended to create an additional meeting room for the building to be used by Bishop Auckland Town Council. The extension infilled a rear yard between two buildings and the existing floor layouts were remodelled to suit the Council’s requirements and each area was refurbished. Large replacement timber sash windows were used throughout due to the poor state of the existing. Natural light was created to the extension by using rooflights and a large glazed element to the only available elevation that glass could be installed due to the site’s constraints.",
    date: "",
    location: "Bishop Auckland",
  },
  {
    id: "p012",
    category: "Extensions and Conversions",
    slug: "windmill-outbuilding",
    title: "Windmill Outbuilding",
    imgURL: "portfolio/p012/P012-01.jpg",
    description: "This replacement single storey outbuilding to an existing two storey farmhouse, in Wind Mill, Bishop Auckland, uses the same footprint of derelict outbuilding that was demolished. The new extension creates an open plan kitchen, dining and living space and a separate utility space and downstairs cloak. The design maximises the views of the countryside and allows natural light to flood the building and a vaulted ceiling creates a sense of space. The existing farmhouse has also been refurbished and provides the bedrooms to the first floor and a family room to the ground floor. Traditional construction materials were used to complement the existing farmhouse and the outbuilding the extension replaces.",
    date: "",
    location: "Wind Mill, Bishop Auckland",
  },
  {
    id: "p019",
    category: "Extensions and Conversions",
    slug: "the-grey-house-extension",
    title: "The Grey House Extension",
    imgURL: "portfolio/p019/P019-01.jpg",
    description: "With a little imagination; we transformed what was an extremely bland existing, grey dwelling without breaking the bank for the client for this property in West Auckland, County Durham. It was a relatively small extension, but the alterations tie nicely in with the adjacent newbuild dwelling on the adjacent site using timber cladding and replacement windows as per the existing dwelling. The new structural element created an outdoor balcony with bi-fold doors that overlook the open countryside and transformed the elevations. ",
    date: "",
    location: "West Auckland, County Durham",
  },
  {
    id: "p021",
    category: "Extensions and Conversions",
    slug: "sherwood-close-extension",
    title: "Sherwood Close Extension",
    imgURL: "portfolio/p021/P021-01.jpg",
    description: "This project was for a first-floor extension and garage conversion to this existing property in Barnard Castle. The extension provides a large master bedroom with ensuite whilst the garage conversion provides a snug area for the family. The materials and style of the extension complemented the existing property being extended.",
    date: "",
    location: "Barnard Castle",
  },
	{
    id: "p089",
    category: "Extensions and Conversions",
    slug: "darlington-family-extension",
    title: "Darlington Family Extension",
    imgURL: "portfolio/p089/P089-01.jpg",
    description: "This large two-storey extension to an existing property in Darlington provided a family room and gym to the ground floor complete with a shower room and two additional bedrooms to the first floor - including a master-bedroom with ensuite. Large-glazed areas have been incorporated into the design to the south elevation by introducing bifold doors to the ground floor and a Juliet balcony to the first floor that overlook the large garden area. The design of the elevations aimed to avoid what could have been a large mass of a building with the introduction of a large extension, therefore, the extension projects beyond to the host building to the front and back. The size of the building is now reflective of the large curtilage this dwelling has.",
    date: "",
    location: "Darlington",
  },
	{
    id: "p079",
    category: "Extensions and Conversions",
    slug: "st-marys-close",
    title: "St Marys Close",
    imgURL: "portfolio/p079/P079-01.jpg",
    description: "This project was for a two-storey rear extension with substantial internal alterations and a new front porch at this property in Shincliffe Village, County Durham. The internal space has been reconfigured to give better living arrangement for modern day living. The extension provides an open plan living/kitchen/dining space to the ground floor, a master bedroom to the first floor and provides an increase in floor area to the existing box room providing much needed additional space in an otherwise small dwelling. The external design aim to reduce the impact on the adjoining neighbour as much as possible, whilst meeting the client’s requirements and so a continued roof line with a dormer was used to the area immediately adjacent to this property, whilst the full height two-storey element was positioned to the opposite side of this property and further away. It was decided to use render as opposed to using a brick that didn’t match the existing property. Bi-fold doors and a Juliet balcony were installed to the rear of the property to maximise natural light internally and to overlook the garden.",
    date: "",
    location: "Shincliffe Village, County Durham",
  },
  {
    id: "p070",
    category: "Extensions and Conversions",
    slug: "shincliffe-village-nursery-conversion",
    title: "Shincliffe Village Nursery Conversion",
    imgURL: "portfolio/p070/P070-01.jpg",
    description: "The project required a Change of Use of a former nursery to a dwelling house in Shincliffe Village, County Durham. The previous extension & conservatory to the existing building were demolished and the stone orangery constructed in its place. The orangery will provide an open plan living/ dining/ kitchen space overlooking a courtyard. The internal space was altered to provide a two-bedroom property with the master bedroom being located on the first floor complete with a large dressing area and ensuite. As the building is located within a conservation area and is adjacent to a listed building, the orangery was design with this in mind. The project also includes the conversion of a separate building that were previously used as offices for the nursery to be a self-contained annexe to the converted building. This is achieved by constructed and ground floor area removes the vehicle access to the existing courtyard.",
    date: "",
    location: "Shincliffe Village, County Durham",
  },
  {
    id: "p066",
    category: "Extensions and Conversions",
    slug: "skibbereen-farm-sunroom",
    title: "Skibbereen Farm Sunroom",
    imgURL: "portfolio/p066/P066-01.jpg",
    description: "This small sunroom extension to the rear of an existing farmhouse in Spennymoor, provides some much-needed additional space to a relatively small farmhouse, with the client's budget in-mind. The space provides a dining room and snug area that the existing farmhouse did not have. A rendered external finish and pantiles to match the host dwelling. Whilst the extension was relatively small, the client was really pleased that they eventually had a dining room.",
    date: "",
    location: "Spennymoor",
  },
  {
    id: "p049",
    category: "Extensions and Conversions",
    slug: "woodgra-farm-sunroom",
    title: "Woodgra Farm Sunroom",
    imgURL: "portfolio/p049/P049-01.jpg",
    description: "This project was for the construction of a sunroom extension to a small cottage that needed additional floor space at The Stables, Woodgra, Bishopton. The style and materials of the constructed sunroom extension matched that of the host building to give a cohesive design. The sunroom with its glazing, maximises the views that this property has being situated within the open countryside that was used as the family room.",
    date: "",
    location: "Bishopton",
  },
  {
    id: "p048",
    category: "Extensions and Conversions",
    slug: "thorpe-hall-refurbishment",
    title: "Thorpe Hall Refurbishment",
    imgURL: "portfolio/p048/P048-01.jpg",
    description: "The project required planning and listed building consent as it is located within the grounds of the listed building Thorpe Hall, Co Durham. The works involved the refurbishment of two former dwellings to provide a multi functioning space for the main hall. It was necessary to strengthen the existing roof, including the installation of steel purlins and ridges to allow the existing flat ceiling to removed and a vaulted ceiling to be created - complete with kingpost trusses. By introducing two large aluminium French doors to the rear wall and opening the space by creating a large opening in the former party wall that separated the existing dwellings, has totally transformed this space. The energy efficiency of the building was drastically improved also.",
    date: "",
    location: "Thorpe Hall, County Durham",
  },
  {
    id: "p041",
    category: "Extensions and Conversions",
    slug: "witton-le-wear-sunroom",
    title: "Witton le Wear Sunroom",
    imgURL: "portfolio/p041/P041-03.jpg",
    description: "The existing dwelling had an unused conservatory to the rear of the property in Witton le Wear, County Durham, which was too hot in the winter and too cold in the summer, however, they had fabulous views overlooking the open countryside that they wanted to maximise. Therefore, it was decided that a natural oak sunroom extension be constructed to replace the conservatory. The sunroom has a natural slate roof, a stone plinth to match the style and pattern of the existing dwelling with bi-fold doors. We also also obtained planning permission for a self-contained annexe converting an unused garage on the same site that will be converted at a later date.",
    date: "",
    location: "Witton le Wear, County Durham",
  },
  {
    id: "p034",
    category: "Extensions and Conversions",
    slug: "walcher-gardens-conversion",
    title: "Walcher Gardens Conversion",
    imgURL: "portfolio/p034/P034-01.jpg",
    description: "Initially the client wanted to extend this project to create a large kitchen space, however, I convinced them that this was not required if they remodelled the internal layout, as the footprint of the property was already quite large. Instead, one of the two existing but unused garages were converted to accommodate a new kitchen and the space from the old, relocated kitchen then freed up space for a large dining room to the rear of the property that could utilise the existing French doors to the rear garden. This saved the client quite a sum of money. ",
    date: "",
    location: "",
  },

	{
    id: "p032",
    category: "Extensions and Conversions",
    slug: "ediscum-garth",
    title: "Ediscum Garth",
    imgURL: "portfolio/p032/P032-01.jpg",
    description: "This project in Bishop Auckland included a substantial refurbishment with internal layout alterations and extensive external improvements that included a double height glazed façade to the front of the property that enters a double height internal space by removing an existing bedroom and repositioning it elsewhere in the property, which creates a striking entrance area. The works also included a first-floor room-in-the-roof extension. The building was rendered to further improve the existing elevations and bifold doors were added to the rear of the property overlooking the rear garden. The difference between the before and after is very pleasing and a massive improvement. ",
    date: "",
    location: "Bishop Auckland",
  },

  {
    id: "p030",
    category: "Extensions and Conversions",
    slug: "kirkham-way-extension",
    title: "Kirkham Way Extension",
    imgURL: "portfolio/p030/P030-04.jpg",
    description: "This single storey extension rear extension created some much-needed floor space for an otherwise small dwelling, which was used to accommodate a dining room. The project was based in Auckland Park in County Durham. The materials used for the extension matched the existing and bifold doors were added to provide natural light to the internal areas and to allow the client to create an indoor-outdoor space. ",
    date: "",
    location: "Auckland Park, County Durham",
  },
  {
    id: "p028",
    category: "Extensions and Conversions",
    slug: "crook-hall-extension",
    title: "Crook Hall Extension",
    imgURL: "portfolio/p028/P028-01.jpg",
    description: "A single storey sunroom side extension to an existing property adjacent to Crook Hall, Durham, was constructed following planning, listed building and building regulation consents obtained by Boxas Ltd. The project used imported bricks to best match the host dwelling for the wall construction, which were 55mm high. Bi-fold doors were added so that the client can fully enjoy the outdoor space of their plot. The impact of the extension was minimised by using a flat roof and flat rooflights.",
    date: "",
    location: "Crook Hall, Durham",
  },
  {
    id: "p023",
    category: "Extensions and Conversions",
    slug: "elemore-grange-farm-outbuilding",
    title: "Elemore Grange Farm – Outbuilding",
    imgURL: "portfolio/p023/P023-02.jpg",
    description: "An existing outbuilding that was previously used for domestic storage was converted to create a small, single bedroom annexe to the main dwelling - situated on the same site in Littletown, County Durham. Several new glazed openings were created to best take advantage of the buildings outlooks and the whole property was re-rendered and hardstanding areas were also added, which was also done to the main dwelling so both buildings complemented each other. The completed project is one of two phases, the second phase can accommodate further bedrooms and a dining room within the same building should the client wish complete these works.",
    date: "",
    location: "Littletown, County Durham",
  },
 
	 // Computer Generated Images (CGIs)
	 {
    id: "pcgi003",
    category: "Computer Generated Images",
    slug: "elemore-grange-farm-cgi",
    title: "Elemore Grange Farm – CGI Visualisations",
    imgURL: "portfolio/pcgi003/P003-CGI-01.png",
    description: "High-quality CGI visualisations were produced for the Elemore Grange Farm extension project, showcasing the seamless integration of the new design with the existing property. The renders highlight the two-storey rear and side extensions, emphasizing the modern, accessible living space created for the family.",
    date: "",
    location: "Littletown",
  },
  {
    id: "pcgi004",
    category: "Computer Generated Images",
    slug: "the-elders-cgi",
    title: "The Elders – Architectural CGI Renders",
    imgURL: "portfolio/pcgi004/P004-CGI-01.png",
    description: "Detailed 3D renders were created for The Elders, a modern residential development in Barnard Castle. The CGIs illustrate the contrast between the two house types, bringing to life the high-spec materials and the striking architectural features of the dwellings.",
    date: "",
    location: "Barnard Castle",
  },
	{
    id: "pcgi006",
    category: "Computer Generated Images",
    slug: "brandon-village-rebuild-cgi",
    title: "Brandon Village Rebuild CGI",
    imgURL: "portfolio/pcgi006/P006-CGI-01.png",
    description: "A stunning CGI representation of a rebuilt dwelling located in Brandon Village. This modern design highlights the use of timber cladding, expansive sliding glass doors, and a contemporary roof structure. The dwelling seamlessly blends into its environment while showcasing a fresh, modern aesthetic.",
    date: "",
    location: "Brandon Village",
  },
  {
    id: "pcgi007",
    category: "Computer Generated Images",
    slug: "toronto-cgi",
    title: "Toronto – CGI Concept Visuals",
    imgURL: "portfolio/pcgi007/P007-CGI-01.png",
    description: "Photorealistic CGI images were generated for the Toronto residential development, providing a clear representation of the six-plot site layout and individual home designs. These renders helped the client visualise the completed development before construction commenced.",
    date: "",
    location: "Toronto",
  },
  {
    id: "pcgi012",
    category: "Computer Generated Images",
    slug: "windmill-outbuilding-cgi",
    title: "Windmill Outbuilding – CGI Presentation",
    imgURL: "portfolio/pcgi012/P012-CGI-01.png",
    description: "CGI representations of the Windmill Outbuilding project illustrate the transformation from a derelict structure to a modern open-plan living space. The renders focus on the vaulted ceiling and panoramic views of the countryside, which were central to the design.",
    date: "",
    location: "Wind Mill, Bishop Auckland",
  },
  {
    id: "pcgi018",
    category: "Computer Generated Images",
    slug: "etherley-bank-council-cgi",
    title: "Etherley Bank – CGI Feasiblity",
    imgURL: "portfolio/pcgi018/P018-CGI-01.png",
    description: "A series of high-resolution CGIs were created to visualise the proposed extensions and renovations to Bishop Auckland Town Council’s headquarters. The renders showcase the expanded meeting room, natural light enhancements, and the integration of modern design elements within the historic setting.",
    date: "",
    location: "Bishop Auckland",
  },
  {
    id: "pcgi019",
    category: "Computer Generated Images",
    slug: "the-grey-house-cgi",
    title: "The Grey House – CGI Design Visuals",
    imgURL: "portfolio/pcgi019/P019-CGI-01.png",
    description: "CGI renderings were used to conceptualise The Grey House’s transformation, highlighting the addition of timber cladding and the structural outdoor balcony. These images effectively conveyed the contemporary upgrades and enhanced countryside views.",
    date: "",
    location: "West Auckland, County Durham",
  },
  {
    id: "pcgi035",
    category: "Computer Generated Images",
    slug: "deneburn-court-cgi",
    title: "Deneburn Court – CGI Residential Renders",
    imgURL: "portfolio/pcgi035/P035-CGI-01.png",
    description: "3D visualisations were developed for Deneburn Court, providing a realistic preview of the four-plot development in Bishop Auckland. The renders emphasize the stone frontages and the balance between traditional and contemporary architectural elements.",
    date: "",
    location: "Bishop Auckland",
  },
  {
    id: "pcgi053",
    category: "Computer Generated Images",
    slug: "etherley-farm-cgi",
    title: "Etherley Farm – CGI Concept Renders",
    imgURL: "portfolio/pcgi053/P053-CGI-01.png",
    description: "CGI renderings were created to showcase the six-bedroom detached dwelling at Etherley Farm. The images illustrate the spacious interiors, landscaped surroundings, and the use of natural materials in the design.",
    date: "",
    location: "Bishop Auckland",
  },
  {
    id: "pcgi056",
    category: "Computer Generated Images",
    slug: "shincliffe-village-nursery-cgi",
    title: "Shincliffe Village Nursery",
    imgURL: "portfolio/pcgi056/P056-CGI-01.png",
    description: "This project involved producing high-quality CGIs to visualise the refurbishment of two former dwellings at Thorpe Hall. The renders effectively communicated the updated layouts and the historical sensitivity of the renovation.",
    date: "",
    location: "Thorpe Hall, County Durham",
  },
  {
    id: "pcgi063",
    category: "Computer Generated Images",
    slug: "witton-park-cgi",
    title: "Witton Park – CGI Design Visuals",
    imgURL: "portfolio/pcgi063/P063-CGI-01.png",
    description: "CGI visualisations of the Shincliffe Village Nursery conversion project provided a detailed representation of the stone orangery addition, open-plan living spaces, and the new courtyard overlooking the historic setting.",
    date: "",
    location: "Shincliffe Village, County Durham",
  },
]



module.exports = projects;